<template lang="pug">
  v-dialog(offset-y left scrollable :close-on-content-click="false" min-width="320px" max-width="800px" v-model="showing").text-center
    template(v-slot:activator="{ on }")
      slot(name="activator" :on="on" :toggle="toggle")
        v-btn(data-test="c-email-templates-btn" icon small @click="toggle").open-btn
          slot(name="tooltip")
            v-icon mdi-email

    v-card
      v-card-title(data-test="c-accept-title").card-title
        span.card-header
          v-btn(icon color="black" small @click="toggle").mr-2
            v-icon mdi-arrow-left
          span.email-templates-title Compose email
          v-menu(bottom left)
            template(v-slot:activator='{ on, attrs }')
              v-btn(color="primary" icon v-bind="attrs" v-on="on" data-test="email-templates-actions-menu").additional-menu
                 v-icon mdi-dots-vertical
            v-list
              v-list-item
                v-btn(
                  @click="save"
                  outlined
                  :loading="processing"
                  :disabled="!selector.activeElement || selector.activeElement.readonly"
                  color="primary"
                  data-test='c-email-templates-save'
                ).list-button save

              v-list-item
                v-btn(
                  @click="showDeleteConfirmationModal"
                  :disabled="!selector.activeElement || selector.activeElement.readonly || newTemplateCreating"
                  color="error"
                  :loading="processing"
                  data-test='c-email-templates-delete'
                ).list-button delete
      v-divider
      v-card-text
        v-row.email-templates
          v-col( data-test="c-email-templates").email-templates__content
            v-progress-circular(indeterminate size=34 data-test="c-email-templates-loading" v-if="itemsLoading" color="warning")

            div(v-if="!itemsLoading")
              .templates-create
                h3.email-templates__subtitle Choose email template
                v-spacer
                .templates-create__btn
                  appLabel(
                    text="add"
                    @click="addCustomLabel"
                    :active="newTemplate.active"
                    data-test="c-email-templates-create"
                  )
                    v-icon(small) mdi-plus
                    span Add

              .templates-grid
                .templates-grid__item( v-for="(item, index) in showingTemplates" :key="item.label" )
                  appLabel(

                    :text="item.label"
                    :active="item.active"
                    @click="select(item)"
                    data-test="c-template"
                  )
                .templates-grid__item
                  appLabel(
                    :text="templateShowingToggleText"
                    @click="toggleTemplatesShowing"
                    v-show="!hasLessTemplatesThatNeedToShow"
                    data-test="c-template-show-toggle"
                  )

              .email-templates__create(v-if="editedTemplate && selector.activeElement")
                customTemp(
                  :item="editedTemplate"
                  :key="editedTemplate.label"
                  :formErrors="formErrors"
                  data-test="c-templates-custom"
                  ref="editor"
                )

          slot(name="side")
      v-card-actions
      div
        v-divider
        .d-flex.pl-5.pr-5.pt-3.pb-3.flex-wrap
          confirmation-dialog(
            v-model="deleteConfirmationModal"
            title="Delete email"
            descr="Are you sure you want to delete email?"
            :failText="'Cancel'"
            :okText="'Delete'"
            @okBtnClicked="remove()"
          )
          v-spacer
          v-btn(
            @click="toggle"
            outlined
            color="primary"
            data-test='c-email-templates-cancel'
          ).mr-3 cancel
          v-btn(
            :disabled="!selector.activeElement"
            @click="submit"
            :loading="processing"
            color="primary"
            data-test='c-email-templates-submit'
          ) send

</template>

<script>
import {CustomEmailTemplateModel, EmailTemplateSelector as Template} from "@/app/admin/modules/candidates/core/models/CandidatesEmailTemplateModel"
import Selector, {SelectBase} from "@/util/selector"

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    maxItemsShowing: Number,
    itemsLoading: Boolean,
    logs: Array,
    logsLoading: Boolean,
    editedTemplate: Object,
    loadData: Function,
    formErrors: Object,
    processing: Boolean
  },

  data: () => ({
    showing: false,
    label: null,
    templates: [],
    showingAllTemplates: false,
    selector: new Selector(),
    newTemplate: new SelectBase(),
    deleteConfirmationModal: false,
  }),

  mounted() {
    // this.loadData()
  },

  computed: {
    showingTemplates() {
      if(this.showingAllTemplates) return this.templates
      return this.templates.slice(0, this.maxItemsShowing)
    },

    templateShowingToggleText() {
      if(!this.showingAllTemplates) return `show all(${this.templates.length})`
      return 'hide'
    },

    hasLessTemplatesThatNeedToShow() {
      if(this.maxItemsShowing >= this.templates.length) return true
      return false
    },

    newTemplateCreating() {
      if(this.selector.activeElement && this.selector.activeElement.label === this.newTemplate.label) return true
      return false
    }
  },

  methods: {
    showDeleteConfirmationModal(item) {
      this.deleteConfirmationModal = true
      this.deletingItem = item
    },

    toggle() {
      this.showing = !this.showing
    },

    select(item) {
      this.selector.set(item)
      this.$emit('templateSelected', item)
    },

    async submit() {
      this.$emit('submit', this.$refs.editor.getData(), this.toggle)
    },

    save() {
      if(this.newTemplateCreating) {
        this.$emit('save', this.$refs.editor.getData(), this.toggle)
        return
      }
      let data = {
        ...this.$refs.editor.getData(),
        ID: this.selector.activeElement.ID
      }
      this.$emit('update', data, this.toggle)
    },

    remove() {
      this.$emit('remove', this.selector.activeElement)
    },

    addCustomLabel() {
      this.select(this.newTemplate)
      this.$emit('onAddCustomTemplate')
    },

    toggleTemplatesShowing() {
      this.showingAllTemplates = !this.showingAllTemplates
    }
  },

  watch: {
    showing(isShowing) {
      if(isShowing)  {
        this.loadData({cache: true})
      }
    },

    items: {
      immediate: true,
      handler: function(newItems) {
        if(!newItems) return
        this.templates = newItems.map(item => new Template(item))
        this.selector.reset()
      }

    }
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    customTemp: () => import('./CandidateCustomETemp.vue'),
    appLabel: () => import('@/components/global/OutlinedLabel.vue')
  },
}
</script>

<style lang="scss" scoped>
.templates-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  &__item {
    flex: 0 0 33.3333333333%;
    padding: 4px;
  }
}

.templates-create {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &__btn {
    width: 33.3333333333%;
    padding-left: 4px;

    .v-icon {
      color: inherit;
    }
  }
}

.email-templates {
  &__create {
    margin-top: 24px;
  }
  &__subtitle {
    font-size: 14px;
    color: $title-second;
  }
}

.email-templates-title {
  color: $title-second;
}

.open-btn {
  color: rgba(0, 0, 0, 0.54);
}
.card-title {
  padding: 10px !important;
}
.card-header {
  width: 100%;
  display: flex;
  align-items: center;
  .additional-menu {
    margin-left: auto;
  }
}
</style>

<style lang="scss">
.email-templates__subtitle {
  font-size: 14px;
  color: $title-second;
}

.list-button {
  width: 100% !important;
}
</style>
